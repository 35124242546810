/* global css */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  line-height: inherit;
  background: #101010;
  font-family: "Source Sans Pro", sans-serif;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}
img,
svg {
  max-width: 100%;
}
button {
  cursor: pointer;
  border: none;
  outline: none;
}

.appContainer {
  margin-left: auto;
  margin-right: auto;

  width: 90%;
  max-width: 1536px;
}
.sectionPadding {
  padding: 28px 0;
}

.sectionHeading {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 125%;
  font-weight: 700;
  color: rgb(255 255 255);
}
.sectionHeading {
  position: relative;
  display: inline-block;
}
.highlight {
  color: rgb(29 204 29);
}

/* @media (max-width: 1536px) {
  .appContainer {
    max-width: 1280px;
    width: 90%;
  }
} 
 @media (max-width: 1280px) {
  .appContainer {
    max-width: 992px;
  }
}
@media (max-width: 1024px) {
  .appContainer {
    max-width: 768px;
  }
} */
@media (max-width: 767px) {
  /* .appContainer {
    max-width: 640px;
  } */
  .sectionHeading {
    font-size: 30px;
  }
}
/* @media (max-width: 640px) {
  .appContainer {
    padding: 0;
    width: 90%;
  }
} */
