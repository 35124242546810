.wrapper {
  position: relative;
  padding: 50px 0;
}
.headingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.heading::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 0px;
  background: url("../../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}
.utilityTokenCard {
  background-color: rgb(255 255 255 / 0.3);
  padding: 2rem;
  text-align: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border-radius: 6px;
  border: 1px px solid rgb(29 204 29);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .sliderWrapper {
  padding: 0 10px;
} */
.title {
  width: 100%;
  text-align: center;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 900;
  color: rgb(29 204 29);
}
.tagline {
  display: block;
  text-align: center;
}
.figure {
  height: 220px;
}
@media only screen and (max-width: 1600px) {
  .figure {
    height: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .figure {
    height: 160px;
  }
  .headingContainer {
    padding-bottom: 0px;
  }
}
@media only screen and (max-width: 640px) {
  .wrapper {
    padding: 30px 10px;
  }
  .figure {
    height: 200px;
  }
}
