.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.heading {
  padding-bottom: 15px;
  text-align: center;
}
.heading::before {
  content: "";
  position: absolute;
  left: auto;
  right: -12px;
  top: -8px;
  transform: rotate(90deg);
  background: url("../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}
.teamWrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;

  width: 100%;
  margin: 0 auto;
}
.teamCard {
  width: calc(20% - 12px);
  border: 1px solid rgb(29 204 29);
}
.secondTeamCard {
  width: calc(25% - 12px);
}
.teamCard .figure img {
  display: block;
  object-fit: cover;
}

.teamCard figcaption {
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  background-color: rgb(0 0 0);
  padding: 12px;

  color: rgb(255 255 255);
}
.nameAndPosition {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.name {
  font-size: 20px;
  line-height: 125%;
  font-weight: 500;
  text-transform: uppercase;
}
.position {
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  color: rgb(29 204 29);
}

@media only screen and (max-width: 1399px) {
  .teamCard {
    width: calc(25% - 12px);
  }
}
@media only screen and (max-width: 1199px) {
  .teamCard {
    width: calc(33.33% - 12px);
  }
}
@media only screen and (max-width: 991px) {
  .teamWrapper {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  .teamCard {
    width: calc(50% - 12px);
  }
}

@media only screen and (max-width: 575px) {
  .teamWrapper {
    max-width: 300px;
  }
  .teamCard {
    width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  .heading {
    padding: 0;
  }
  .heading::before {
    display: none;
  }
  .name {
    font-size: 14px;
    line-height: 120%;
  }
}
