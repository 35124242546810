.mainWrapper {
  background: #101010;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 6px;
}
.heading::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 0px;
  background: url("../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}

.mediaContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.media {
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 4px;
  width: 140px;
}
.media img {
  display: block;
}
@media only screen and (max-width: 450px) {
  .mediaContainer {
    gap: 15px;
  }
  .media {
    width: 135px;
  }
}
