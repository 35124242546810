.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.heading {
  padding-bottom: 15px;
}
.heading::before {
  content: "";
  position: absolute;
  left: auto;
  right: -12px;
  top: -8px;
  transform: rotate(90deg);
  background: url("../../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}
.teamWrapper {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  max-width: 1200px;
  
  margin: 0 auto;
}
.teamCard {
  width: 22.5%;
  border: 1px solid rgb(29 204 29);
}

.teamCard figcaption {
  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  background-color: rgb(0 0 0);
  padding: 12px;

  color: rgb(255 255 255);
}

.name {
  font-size: 20px;
  line-height: 100%;
  font-weight: 500;
  text-transform: uppercase;
}
.position {
  font-size: 14px;
  line-height: 125%;
  font-weight: 400;
  color: rgb(29 204 29);
}
.iconContaner {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid rgb(29 204 29);
  margin-left: auto;
}

.linkedinIcon {
  font-size: 14px;
  color: rgb(29 204 29);
}
.button {
  width: 100%;
  background: transparent;
  border: solid 1px #1dcc1d;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  border-radius: 4px;
  padding: 15px 8px;
  transition: ease-in-out 0.4s;
  margin-top: 8px;
}
.button:hover {
  background: #1dcc1d;
  color: #fff;
}
@media only screen and (max-width: 1399px) {
  .teamCard {
    width: calc(25% - 12px);
  }
}
@media only screen and (max-width: 1199px) {
  .teamCard {
    width: calc(33.33% - 12px);
  }
}
@media only screen and (max-width: 991px) {
  .teamWrapper {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
  .teamCard {
    width: calc(50% - 12px);
  }
}

@media only screen and (max-width: 575px) {
  .teamWrapper {
    max-width: 300px;
  }
  .teamCard {
    width: 100%;
  }
}
@media only screen and (max-width: 450px) {
  .name {
    font-size: 14px;
    line-height: 120%;
  }
  .teamCard {
    width: 90%;
  }
}
