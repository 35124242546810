#tokenUtility .slick-slide {
  padding: 0 18px;
  position: relative;
}

/* #tokenUtility .slick-list,
#tokenUtility .slick-slider {
  padding: 1.5rem 0;
} */
#tokenUtility .slick-slider {
  padding-bottom: 20px;
}
#tokenUtility .slick-track {
  padding: 70px 0;
}
#tokenUtility .center .slick-center .project {
  transform: scale(1.4);

  z-index: 10000;
  transition: all 400ms ease-in-out;
  pointer-events: all;
  opacity: 1;
  background-color: rgb(0 0 0);
  color: rgb(255 255 255);
}

#tokenUtility .center .project {
  opacity: 0.5;
  pointer-events: none;
  border: 1px solid #fff;
  height: auto;
}
#tokenUtility .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;

  list-style-type: none;
}
#tokenUtility .slick-dots li {
  margin: 0 0.25rem;
}

#tokenUtility .slick-dots li button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;

  border: none;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.2);

  text-indent: -9999px;
}

#tokenUtility .slick-dots .slick-active button {
  background-color: #1dcc1d;
}

@media only screen and (max-width: 1600px) {
  #tokenUtility .slick-slide {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 1399px) {
  #tokenUtility .slick-slide {
    padding: 10px;
  }
}

@media (max-width: 991px) {
}
@media (max-width: 767px) {
  #tokenUtility .center .slick-center .project {
    transform: scale(1.3);
  }
  #tokenUtility .slick-track {
    padding: 50px 0;
  }
}
@media (max-width: 640px) {
  #tokenUtility .center .slick-center .project {
    transform: scale(1);
  }
  #tokenUtility .slick-track {
    padding: 10px 0;
  }
  #tokenUtility .center .project {
    opacity: 1;
    background-color: rgb(0 0 0);
    color: rgb(255 255 255);
  }
}
