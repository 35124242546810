.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.heading::before {
  content: "";
  position: absolute;
  left: auto;
  right: -12px;
  top: -8px;
  transform: rotate(90deg);

  background: url("../../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}
.tokenomics {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  max-width: 880px;
  height: 320px;
  width: 100%;
  padding: 25px;

  padding-bottom: 50px;
  margin: 0 auto;
  background-color: #101010;
  border: 1px solid #1dcc1d;
  border-radius: 20px;
}
.chartContainer {
  transform: translateY(20px);
  max-width: 770px;
  margin: 0 auto;
  width: 100%;
  height: calc(100% + 10px);
}
.rechartsWrapper text {
  fill: white;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
  word-break: break-all;
  text-align: center;
  width: 30px;
}
.rechartsWrapper .topLabel {
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
}
.tooltip {
  color: #000;

  width: 140px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 3px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  transition: margin 0.35s;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.label {
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}
.value {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}
.total {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;

  color: rgb(255 255 255);
}
@media only screen and (max-width: 1024px) {
  .tokenomics {
    max-width: 750px;
  }
}
@media only screen and (max-width: 520px) {
  .rechartsWrapper text {
    font-size: 8px;
  }
  .tokenomics {
    padding: 12px;
    padding-bottom: 35px;
  }
  .rechartsWrapper .topLabel {
    font-size: 15px;
  }
}
