.mainWrapper {
  background: #101010;
  border-top: 1px solid #342f2f;
  padding-top: 40px;
}
.wrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 35px;
  align-items: start;
}
.title {
  font-size: 36px;
  line-height: 110%;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.addressContainer,
.joinContainer,
.associatePartnersContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.address {
  font-size: 18px;
  line-height: 125%;
  color: #fff;
  text-align: center;
}
.partners {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.fourPartners {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
.fivePartners {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
}
.caption {
  font-size: 13px;
  line-height: 140%;
  color: #fff;
  text-align: center;
}
.joinButton {
  background: transparent linear-gradient(106deg, #1dcc1d 0%, #fff947 100%) 0%
    0% no-repeat padding-box;
  border-radius: 8px;
  color: #000;
  padding: 10px 12px;
  width: 192px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
@media only screen and (max-width: 1399px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
  .fourPartners {
    gap: 10px;
  }
  .partners {
    order: 1;
  }
  .addressContainer {
    order: 2;
  }
  .joinContainer {
    order: 3;
  }
}
@media only screen and (max-width: 767px) {
  .fourPartners,
  .fivePartners {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .partner {
    max-width: calc(50% - 20px);
    width: 50%;
    display: block;
    padding-top: 10px;
  }
}
@media only screen and (max-width: 640px) {
  .associatePartnersContainer {
    gap: 10px;
  }
}
@media only screen and (max-width: 360px) {
  .partner {
    max-width: 100%;
    width: 100%;
  }
}
