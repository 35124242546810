.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 0px;
  background: url("../../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}

.projects {
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 15px 0rem;
  padding-top: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 45px;
}

.project {
}
.imageContainer {
  position: relative;
}
.imageContainer::after {
  content: "";
  position: absolute;
  background-color: #0c560c;
  left: 12px;
  width: 100%;
  top: -12px;
  height: 100%;
  border-radius: 4px;
  transition: ease-in-out 0.5s;
}
.imageContainer:hover::after {
  left: 0;
  border-radius: 0px;
  top: 0;
  opacity: 0;
}
.imageContainer img {
  width: 100%;
  position: relative;
  z-index: 2;
  border-radius: 4px;
}
.projectName {
  font-weight: 600;
  font-size: 25px;
  margin-top: 15px;
  line-height: 125%;
  text-align: center;
  color: #e5e7eb;
}
@media only screen and (max-width: 1024px) {
  .projects {
    gap: 30px;
  }
  .projectName {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .projects {
    grid-template-columns: 1fr;
    gap: 45px;
  }
  .projectName {
    margin-top: 10px;
  }
}
