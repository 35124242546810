.about {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 15px;
  align-items: center;
}
.heading {
  padding-bottom: 15px;
}
.heading::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 0px;
  background: url("../../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}
.figure {
  width: 100%;
}
.headingAndCards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.card {
  display: flex;
  align-items: center;
  gap: 12px;
  background: #1e232a;
  padding: 20px;
  --text-opacity: 1;
  color: rgb(255 255 255 / var(--text-opacity));
  --backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--backdrop-blur) var(--backdrop-brightness)
    var(--backdrop-contrast) var(--backdrop-grayscale)
    var(--backdrop-hue-rotate) var(--backdrop-invert) var(--backdrop-opacity)
    var(--backdrop-saturate) var(--backdrop-sepia);
  backdrop-filter: var(--backdrop-blur) var(--backdrop-brightness)
    var(--backdrop-contrast) var(--backdrop-grayscale)
    var(--backdrop-hue-rotate) var(--backdrop-invert) var(--backdrop-opacity)
    var(--backdrop-saturate) var(--backdrop-sepia);
  margin-left: 0px;
  margin-right: 0px;
}

.icon {
  max-width: 100%;
  min-width: 80px;
}
.imageContainer {
  width: 100%;
}
.textContainer {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.title {
  font-size: 16px;
  font-weight: 700;
}
.info {
  font-size: 15px;
}

@media only screen and (max-width: 1280px) {
  .about {
    display: flex;
    flex-direction: column;
  }

  .figure {
    max-width: 600px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 1024px) {
  .about {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .headingAndCards {
    align-items: center;
  }
  .info {
    font-size: 13px;
  }
}
@media only screen and (max-width: 640px) {
  .card {
    padding: 15px;
  }
  .title {
    font-size: 14px;
  }
  .icon {
    min-width: 48px;
    height: 48px;
  }
}
@media only screen and (max-width: 450px) {
  .card {
    padding: 12px;
  }
  .title {
    font-size: 13px;
  }
  .info {
    font-size: 12px;
  }
}
