.wrapper {
  position: relative;
}
.sliderWrapper {
  max-width: 1024px;

  width: calc(100% - 170px);
  margin: 0 auto;
}
.imageContainer {
  width: 100%;
  border: 1px solid rgb(29 204 29);
  border-radius: 10px;
  overflow: hidden;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  min-height: 200px;
}
.nextButton,
.previousButton {
  padding: 10px 10px;
  margin: 0 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  height: 40px;
  width: 70px;
  position: absolute;
  top: calc(50% - 30px);
  z-index: 500;
  border: 1px solid rgb(29 204 29);
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.previousButton {
  left: -100px;
}
.nextButton {
  right: -100px;
}
.arrow {
  color: #fff;
  font-size: 28px;
}

@media only screen and (max-width: 1024px) {
  .previousButton {
    left: -90px;
  }
  .nextButton {
    right: -90px;
  }
}

@media only screen and (max-width: 767px) {
  .sliderWrapper {
    width: 100%;
  }
}
@media only screen and (max-width: 520px) {
  .title {
    font-size: 40px;
  }
  .nextButton,
  .previousButton {
    left: 50%;
    bottom: -80px;
  }
  .previousButton {
    right: auto;
    left: calc(50% - 40px);
    transform: translateX(-50%);
  }
  .nextButton {
    left: auto;
    right: calc(50% - 40px);
    transform: translateX(50%);
  }
}
