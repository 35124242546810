.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.heading::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 0px;
  background: url("../../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}
.title {
  font-size: 20px;
  font-weight: 400px;
  line-height: 130%;
  color: #fff;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}
.roadmap {
  position: relative;
  margin-top: 40px;
}

.roadmap::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 10px;
  height: 100%;
  background: #515151;
}
.roadmapCard {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
}

.storyDateCard {
  z-index: 20;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  width: 144px;
  max-width: 100%;
  border-radius: 6px;

  border: 1px solid rgba(255, 255, 255, 1);
  background-color: rgba(29, 204, 29, 1);
  padding-top: 16px; /* 1rem = 1 * 16 = 16px */
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: left;
  font-size: 20px;
  font-weight: 900;
  line-height: 20px;

  color: rgba(0, 0, 0, 1);
}

.storyCard {
  padding-left: 40px;
  padding-right: 40px;
}

.storyContent {
  overflow-wrap: break-word;
}

.storyContent h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #fff;
  margin: 20px 0;
}

.storyDateCard h4 span {
  display: block;
}

.storyContent h4 > span {
  display: block;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;

  color: rgba(29, 204, 29);
}

.roadmapCard {
  position: relative;
}

.roadmapCard::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: #515151;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.roadmapCard::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  left: 40%;
  height: 5px;
  width: 20%;
  z-index: 10;
  background: #515151;
  transform: translate(-50%, -50%);
}

.roadmapCard.invertCard::after {
  left: 60%;
}

.roadmapCard.invertCard .storyContent {
  text-align: right;
}
@media only screen and (max-width: 640px) {
  .title {
    font-size: 16px;
  }
  .storyContent h4 {
    margin: 5px 0;
    font-size: 16px;
    line-height: 200%;
  }
  .storyCard {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (max-width: 380px) {
  .storyContent h4 {
    font-size: 12px;
    margin: 0;
  }
  .storyDateCard {
    width: 100px;
    font-size: 14px;
    padding: 10px;
  }
}
