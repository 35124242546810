.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-bottom: 24px;
}
.figure {
  max-width: 672px;
  width: 100%;
}
.image {
  width: 100%;
}
.heading::before {
  content: "";
  position: absolute;
  left: -12px;
  top: 0px;
  background: url("../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}
.allFaqs {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.faq {
  background: #1f201f;
  padding: 28px 18px;
}
.questionContainer {
  display: flex;
  justify-content: space-between;

  gap: 5px;
  width: 100%;

  cursor: pointer;
}

.question {
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  width: 100%;
}
.icon {
  font-size: 24px;
  line-height: 130%;
  color: #fff;
  min-width: 28px;
}
.answer {
  font-size: 20px;
  line-height: 130%;
  color: #fff;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid rgba(51, 65, 85, 1);
}
.answer .tAndC {
  color: #fff;
}
.socialMediaAnswer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid rgba(51, 65, 85, 1);
  padding-bottom: 15px;
}
.socialMediaContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  gap: 8px;
}
.socialMedia {
  color: rgb(96, 165, 250, 1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  font-size: 14px;
}
.socialMedia:hover {
  color: rgb(37, 99, 235, 1);
}
@media only screen and (max-width: 1024px) {
  .figure {
    max-width: 600px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .faq {
    padding: 18px 18px;
  }
  .question {
    font-size: 18px;
  }

  .answer {
    font-size: 16px;
  }
}
@media only screen and (max-width: 450px) {
  .question {
    font-size: 16px;
  }

  .answer {
    font-size: 14px;
  }
}
