#videoSlider .slick-track {
  padding-bottom: 30px;
}

#videoSlider .slick-dots {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;

  list-style-type: none;
}
#videoSlider .slick-dots li {
  margin: 0 0.25rem;
}

#videoSlider slick-dots li button {
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  padding: 0;

  border: none;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  text-indent: -9999px;
}

#videoSlider .slick-dots .slick-active button {
  background-color: #1dcc1d;
}
@media only screen and (max-width: 767px) {
  #videoSlider .slick-dots li button {
    width: 10px;
    height: 10px;
  }
}
