.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.heading {
  padding-bottom: 15px;
}
.heading::before {
  content: "";
  position: absolute;
  left: auto;
  right: -12px;
  top: -8px;
  transform: rotate(90deg);
  background: url("../../../images/secPath.png") no-repeat;
  background-size: auto;
  background-size: contain;
  width: 35px;
  height: 50px;
}

.projects {
  width: 100%;
  position: relative;
  z-index: 3;
  padding: 1rem 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
.project {
  border: 1px solid rgb(29 204 29);
  padding: 5px;
  position: relative;
}

.imageContainer img {
  width: 100%;
  position: relative;
  z-index: 2;
  display: block;
  border-radius: 4px;
  object-fit: cover;
}
.projectName {
  font-weight: 600;
  font-size: 25px;
  margin-top: 15px;
  line-height: 125%;
  text-align: center;
  color: #e5e7eb;
  position: absolute;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  padding: 18px 8px;
  z-index: 10;
}
@media only screen and (max-width: 1024px) {
  .projects {
    gap: 30px;
  }
  .projectName {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .projects {
    grid-template-columns: 1fr;
    gap: 30px;
  }
  .projectName {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 450px) {
  .projectName {
    padding: 15px 8px;
  }
}
