.mainWrapper {
  background: #101010;
}
.footer {
  max-width: 800px;
  margin: 0 auto;
  padding: 25px 10px;

  border-top: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  color: #fff;
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  text-align: center;
}
.disclaimer {
  color: #00b9ff;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  border-bottom: 1px solid #00b9ff;
}
