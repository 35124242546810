.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10px;
}
.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.textContainer h1 {
  font-size: 60px;
  line-height: 110%;
  font-weight: 700;
  --text-opacity: 1;
  color: rgb(255 255 255);
}
.block {
  display: block;
}

.countCard {
  position: relative;
  margin-top: 32px;
  box-sizing: border-box;
  width: 100%;
  max-width: 320px;
  border-radius: 16px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  --border-opacity: 1;
  border: 1px solid rgb(29 204 29 / var(--border-opacity));
  background-color: #0c1e33;
  text-align: center;
  --text-opacity: 1;
  color: rgb(255 255 255 / var(--text-opacity));
  --backdrop-blur: blur(16px);
  -webkit-backdrop-filter: var(--backdrop-blur) var(--backdrop-brightness)
    var(--backdrop-contrast) var(--backdrop-grayscale)
    var(--backdrop-hue-rotate) var(--backdrop-invert) var(--backdrop-opacity)
    var(--backdrop-saturate) var(--backdrop-sepia);
  backdrop-filter: var(--backdrop-blur) var(--backdrop-brightness)
    var(--backdrop-contrast) var(--backdrop-grayscale)
    var(--backdrop-hue-rotate) var(--backdrop-invert) var(--backdrop-opacity)
    var(--backdrop-saturate) var(--backdrop-sepia);
  margin-left: 0px;
  margin-right: 0px;
}

.countCard h3 {
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
}

.innerContent {
  padding: 32px;
}
.counterLeft {
  position: absolute;
  left: -16px;
  top: 8px;
}

.counterBottom {
  position: absolute;
  bottom: -18px;
  width: 100%;
}

.rightSide {
  width: 100%;
  position: relative;
}
.coinContainer {
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 10px;
  position: absolute;
  top: 20px;
  right: 0;
}
.coinMarket {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  min-height: 50px;
  border-radius: 59px;
  align-items: center;
  font-size: 15px;
  border: 1px solid #fff;
  padding: 7px 14px;
  padding-right: 14px;
  padding-right: 20px;
  width: auto;
  background: #000;
  /* background: transparent linear-gradient(106deg, #1dcc1d 0%, #fff947 100%) 0%
    0% no-repeat padding-box; */
  color: #fff;
  font-weight: 700;
}
.coinMarket img {
  width: 35px;
}
.figure {
  width: 100%;
}

@media only screen and (max-width: 1399px) {
  .hero {
    padding-top: 20px;
  }
  .figure {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .hero {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    padding-top: 0px;
  }
  .textContainer {
    align-items: center;
  }
  .textContainer h1 {
    text-align: center;
  }
  .figure {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    padding-top: 20px;
  }
  .textContainer h1 {
    font-size: 36px;
    text-align: center;
  }
  .countCard {
    width: auto;
  }
  .coinContainer {
    flex-direction: column;
    position: static;
  }
}
@media only screen and (max-width: 520px) {
  .hero {
    padding-top: 0px;
  }
  .textContainer h1 {
    font-size: 30px;
    text-align: center;
  }
}
